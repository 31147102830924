<template>
  <div class="about">
    <h1 class="errormsgtitle">{{ $t("m.notfound.title") }}</h1>
    <h2>{{ $t("m.notfound.header1") }}</h2>
    <h3>{{ $t("m.notfound.header2") }}</h3>
    <center>
      <img
        class="logo"
        src="https://i.loli.net/2021/03/09/BoC7jHS12XqgGvM.png"
      />
    </center>
    <div class="blank"></div>
    <van-button
      color="#000"
      plain
      round
      v-on:click="backhome()"
      size="large"
      type="info"
      >{{ $t("m.notfound.button") }}</van-button
    >
  </div>
</template>
<style scoped>
.about {
  padding: 30px;
}
.blank {
  height: 60px;
}
.logo {
  width: 280px;
  height: 280px;
}
</style>
<script>
// import GG from '@/assets/svg/notfound.svg';
export default {
  name: "ServerError",
  components: {
    // GG,
  },
  methods: {
    backhome() {
      this.$router.push({ name: "About" });
    },
  },
};
</script>